<template>
  <v-container
    style="margin: auto"
    class="blog fill-height justify-center align-center"
  >
    <h1 style="margin-bottom: 3%; margin-top: 3%">
      How to Cite Movies from DVDs, Streaming Services, etc. in MLA format
    </h1>

    <v-row>
      <p>
        To use the information you find with Pop Mystic’s movie line search
        engine, you will need to cite it, and this article is intended as a
        quick reference for citing the movies and television shows you want to
        quote or analyze in the Modern Language Association (MLA) format. This
        format guide is specifically for direct quotations from movies, tv, and
        video. Much of the information presented here is a synopsis of the
        in-depth and detailed information available at
        <a href="https://www.scribbr.com/mla/how-to-cite-a-movie/"
          >Scribbr.com</a
        >
      </p>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" lg="2" xl="2" align="center" justify="center">
        <v-img
          class="mx-auto"
          src="../../assets/mla/mla-logo.jpg"
          contain
          max-width="150px"
        ></v-img> </v-col
      ><v-col cols="12" md="10" lg="10" xl="10">
        MLA is the format most commonly used in the Humanities. Research papers
        in disciplines like history, literature, philosophy, and visual arts are
        most commonly formatted in MLA style. Disciplines like
        <a href="https://en.wikipedia.org/wiki/Film_studies">Film</a> and
        <a href="https://en.wikipedia.org/wiki/Media_studies">Media Studies</a>
        fall in this category and are the most likely to need to cite non-text
        sources, so the formatting information presented here is for MLA format.
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>Bibliography Citation</h2>
    </v-row>
    <v-row style="margin-bottom: 20px" align="center" justify="center">
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-img
          class="mx-auto"
          src="../../assets/mla/ghost-dog.jpg"
          contain
          max-width="200px"
        ></v-img>
      </v-col>
      <v-col cols="10" md="10" lg="10" xl="10">
        Format
        <v-card color="primary">
          <v-card-text>
            <i>Movie Title</i>. Directed by Director first name Last name,
            Production Company or Distribution Company, Year.
          </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text>
            <i>Ghost Dog:</i> The Way of the Samurai. Directed by jim Jarmusch,
            Artisan Entertainment,
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <h2>In-Text Citation</h2>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="10" md="8" lg="8" xl="8">
        Format
        <v-card color="primary">
          <v-card-text> (<i>Title</i> 00:00-00:00). </v-card-text>
        </v-card>
        <br />
        Example
        <v-card color="secondary">
          <v-card-text>
            Ghost Dog then tells Marcel, the ice cream man, that, ”This is
            better than the ice cream in Brownsville.” (<i>Ghost Dog</i>
            19:18-19:24).
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" lg="4" xl="4">
        <v-img
          class="mx-auto"
          src="../../assets/mla/ghost-dog-ice-cream.jpg"
          contain
          max-height="300px"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MlaMovie",

  components: {},
};
</script>

<style scoped>
.blog {
  font-weight: 600;
  color: black;
}
h2 {
  text-decoration: underline;
  text-align: center;
}
h1 {
  text-align: center;
}
p {
  text-align: center;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: black;
}
.container {
  max-width: 900px;
}
</style>
